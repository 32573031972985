import { number, object } from 'yup';
import { email, text } from '@proliance-ai/yup';

export const getSchema = (addItemId: number) => object()
  .shape({
    teamArea: text().required('required'),
    userId: number().nullable().required('required'),
    email: email().nullable()
      .when('userId', {
        is: (value: number): boolean => value === addItemId,
        then: (schema) => schema.required('required')
      }),
    firstName: text().nullable(),
    lastName: text().nullable()
  });
