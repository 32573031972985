import React, { ReactElement, useEffect } from 'react';
import { Namespace } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { take } from 'rxjs/operators';
import { ToggleButtonRenderingProperties } from '@proliance-ai/typings';
import {
  AddressForm,
  ErrorFactory,
  modalService,
  notificationService,
  RadioButtonGroup
} from '@proliance-ai/react-ui';
import { useRequiredFields } from '@proliance-ai/yup';
import { Input, IPhoneData, Phone } from '@proliance-ai/design-system';
import { dictionaryService, Principal, PrincipalData, SystemType } from '@services';
import { FormComponentProperties } from '@shared/hooks';

import { schema } from './schema';
import styles from '../FormComponent.styl';

const PrincipalFormComponent = ({
  textNamespace,
  formNamespace,
  translationKey,
  buttonTranslationKey,
  entity = {} as Principal,
  onClose,
  setForm,
  setIsLoading,
  setIsDirty,
  callback,
  createMethod,
  updateMethod
}: FormComponentProperties<Principal, PrincipalData>): ReactElement => {
  const { t } = useTranslation([ textNamespace, formNamespace ] as Namespace);
  const text = t(`${ translationKey }.text`);
  const { id, ...defaultValues } = entity;

  const form = useForm<PrincipalData>({
    resolver: yupResolver(schema),
    defaultValues
  });
  const {
    getValues,
    register,
    handleSubmit,
    watch,
    formState: {
      dirtyFields,
      errors
    }
  } = form;
  const value = getValues();
  const { getRequiredState } = useRequiredFields<PrincipalData>({
    schema,
    value
  });

  useEffect(() => {
    setIsDirty(!!Object.keys(dirtyFields).length);
  }, [ Object.keys(dirtyFields).length ]);

  const nameInput = (
    <div className="inputWrap">
      <Input
        label={ t(`${ formNamespace }:name`) }
        required={ getRequiredState('name') }
        useFormGroup={ false }
        dataAttributesDictionary={ {
          test: { principal: 'name' },
          guide: { principal: 'name' }
        } }
        { ...register('name') }
      />
      <ErrorMessage
        name="name"
        errors={ errors }
        render={ ErrorFactory(t, t(`${ formNamespace }:name`)) }
      />
    </div>
  );

  const legalFormInput = (
    <div className="inputWrap">
      <Input
        label={ t(`${ formNamespace }:legalForm`) }
        required={ getRequiredState('legalForm') }
        useFormGroup={ false }
        dataAttributesDictionary={ {
          test: { principal: 'legalForm' },
          guide: { principal: 'legalForm' }
        } }
        { ...register('legalForm') }
      />
      <ErrorMessage
        name="legalForm"
        errors={ errors }
        render={ ErrorFactory(t, t(`${ formNamespace }:legalForm`)) }
      />
    </div>
  );

  const addressForm = (
    <AddressForm<PrincipalData>
      schema={ schema }
      path="address"
      form={ form }
      dataSource={ dictionaryService.getData }
    />
  );

  const data: ToggleButtonRenderingProperties[] = [
    {
      label: t(`${ buttonTranslationKey }.yes`),
      value: 'true'
    },
    {
      label: t(`${ buttonTranslationKey }.no`),
      value: 'false'
    }
  ];
  const setValueAs = (value: undefined | boolean | string): undefined | string => typeof value === 'boolean'
    ? `${ value }`
    : value;
  const dpoPresentToggleButton = (
    <div className={ `inputWrap ${ styles.toggleButton }` }>
      <RadioButtonGroup
        mode="toggle"
        name="dpoPresent"
        register={ register }
        registerOptions={ { setValueAs } }
        data={ data }
        label={ t(`${ translationKey }.dpoPresent`) }
        required={ getRequiredState('dpoPresent') }
        dataAttributesDictionary={ {
          test: { principal: 'dpoPresent' },
          guide: { principal: 'dpoPresent' }
        } }
      />
      <ErrorMessage
        name="dpoPresent"
        errors={ errors }
        render={ ErrorFactory(t, t(`${ translationKey }.dpoPresent`)) }
      />
    </div>
  );

  const dpoNameInput = (
    <div className="inputWrap">
      <Input
        label={ t(`${ translationKey }.name`) }
        required={ getRequiredState('dpo.name') }
        useFormGroup={ false }
        dataAttributesDictionary={ {
          test: { principal: 'dpoName' },
          guide: { principal: 'dpoName' }
        } }
        { ...register('dpo.name') }
      />
      <ErrorMessage
        name="dpo.name"
        errors={ errors }
        render={ ErrorFactory(t, t(`${ translationKey }.name`)) }
      />
    </div>
  );

  const dpoEmailInput = (
    <div className="inputWrap">
      <Input
        label={ t(`${ formNamespace }:email`) }
        required={ getRequiredState('dpo.email') }
        useFormGroup={ false }
        dataAttributesDictionary={ {
          test: { principal: 'dpoEmail' },
          guide: { principal: 'dpoEmail' }
        } }
        { ...register('dpo.email') }
      />
      <ErrorMessage
        name="dpo.email"
        errors={ errors }
        render={ ErrorFactory(t, t(`${ formNamespace }:email`)) }
      />
    </div>
  );

  const {
    name: phoneName,
    onChange: phoneOnChange,
    onBlur: phoneOnBlur
  } = register(`dpo.phoneNumber`);
  const phoneOnChangeHandler = (value: null | IPhoneData) => {
    phoneOnChange!({
      type: 'change',
      target: { name: phoneName, value }
    });
  };
  const phoneValue = getValues(phoneName);
  const dpoPhoneNumberPhone = (
    <div className="inputWrap">
      <Phone
        name={ phoneName }
        className={ styles.input }
        label={ t(`${ formNamespace }:phone`) }
        useFormGroup={ false }
        getData={ dictionaryService.getData }
        required={ getRequiredState(phoneName) }
        value={ phoneValue }
        onBlur={ phoneOnBlur }
        onChange={ phoneOnChangeHandler }
      />
      <ErrorMessage
        errors={ errors }
        name={ phoneName }
        render={ ErrorFactory(t, t(`${ formNamespace }:phone`)) }
      />
    </div>
  );

  const identicalAddressToggleButton = (
    <div className={ `inputWrap ${ styles.toggleButton }` }>
      <RadioButtonGroup
        mode="toggle"
        name="dpo.identicalAddress"
        register={ register }
        registerOptions={ { setValueAs } }
        data={ data }
        label={ t(`${ translationKey }.identicalAddress`) }
        required={ getRequiredState('dpo.identicalAddress') }
        dataAttributesDictionary={ {
          test: { principal: 'identicalAddress' },
          guide: { principal: 'identicalAddress' }
        } }
      />
      <ErrorMessage
        name="dpo.identicalAddress"
        errors={ errors }
        render={ ErrorFactory(t, t(`${ translationKey }.identicalAddress`)) }
      />
    </div>
  );

  const watchIdenticalAddress = watch('dpo.identicalAddress');
  const dpoAddressForm = watchIdenticalAddress !== 'false'
    ? null
    : (
      <div className={ styles.dpoAddressForm }>
        <AddressForm<PrincipalData>
          schema={ schema }
          path="dpo.address"
          form={ form }
          dataSource={ dictionaryService.getData }
        />
      </div>
    );

  const watchDpoPresent = watch('dpoPresent');
  const dpoText = t(`${ translationKey }.dpoText`);
  const dpoBlock = watchDpoPresent === 'true'
    ? (
      <div className={ styles.dpoBlock }>
        <p>{ dpoText }</p>
        <div className="row">
          { dpoNameInput }
        </div>
        <div className="row">
          { dpoEmailInput }
        </div>
        <div className="row">
          { dpoPhoneNumberPhone }
        </div>
        <div className="row">
          { identicalAddressToggleButton }
        </div>
        { dpoAddressForm }
      </div>
    )
    : null;

  const onSubmit = (body: PrincipalData): void => {
    setIsLoading(true);
    const systemType: SystemType = 'principal';
    const isCreate = !id;
    const method = isCreate
      ? createMethod(systemType, body)
      : updateMethod(systemType, id, body as Principal);
    method
      .pipe(take(1))
      .subscribe(() => {
        if (typeof callback === 'function') {
          callback();
        }
        setIsLoading(false);
        const dataAttributesValue = {
          notificationSuccess: isCreate
            ? 'createPrincipal'
            : 'updatePrincipal'
        };
        const dataAttributesDictionary = {
          test: dataAttributesValue,
          guide: dataAttributesValue
        };
        const textTranslationKey = isCreate
          ? `${ translationKey }.message.createSuccess`
          : `${ translationKey }.message.updateSuccess`;
        notificationService.success({
          namespaces: textNamespace,
          textTranslationKey,
          dataAttributesDictionary
        });
        if (typeof onClose === 'function') {
          onClose();
        }
        modalService.hide();
      });
  };

  const onError = (errors: any): void => {
    console.error('onError', errors);
  };
  return (
    <form
      ref={ setForm }
      className={ `form ${ styles.form }` }
      onSubmit={ handleSubmit(onSubmit, onError) }
    >
      <p>{ text }</p>
      <div className="row">
        { nameInput }
      </div>
      <div className="row">
        { legalFormInput }
      </div>
      { addressForm }
      <div className="row">
        { dpoPresentToggleButton }
      </div>
      { dpoBlock }
    </form>
  );
};

export default PrincipalFormComponent;
