import { Column } from 'react-table';
import { DataStorageCloudHosted, DataStorageThirdPartyHosted } from '@services';
import { Header, ValueCell } from '@proliance-ai/design-system';
import { ColumnsOptions } from './getColumns';

const getDataStorageThirdPartyHostedColumnList = ({
  t,
  systemsType
}: ColumnsOptions): Array<Column<DataStorageThirdPartyHosted>> => [
  {
    accessor: 'name',
    width: 'auto',
    disableFilters: true,
    Header: Header({ t, headingKey: systemsType })
  },
  {
    id: 'serviceProvider',
    accessor: (originalRow: DataStorageCloudHosted) => originalRow.serviceProvider.title,
    width: 'auto',
    disableFilters: true,
    Header: Header({ t, headingKey: systemsType }),
    Cell: ValueCell()
  }
];

export default getDataStorageThirdPartyHostedColumnList;
