import { Router, State } from 'router5';
import { DoneFn } from 'router5/dist/types/base';
import { Subscription } from 'rxjs';
import { ComponentPermissionData } from '@proliance-ai/typings';
import { permissionService } from '@services';
import { RouteListType } from '@router/configuration/routeList';

export interface canActivateArguments {
  toState: State;
  fromState: State;
  done: DoneFn;
}

export interface RedirectData {
  redirect: {
    name: RouteListType;
    params?: Record<string, string>;
  };
}

export interface PromiseArguments {
  resolve: (value: boolean) => void;
  reject: (reason?: boolean | RedirectData) => void;
}

export interface CheckFunctionOptions extends canActivateArguments, PromiseArguments {
  data: ComponentPermissionData;
  router: Router;
}

export type CheckFunction = (options: CheckFunctionOptions) => void;

export const canActivateByPermission = (
  checkFunction: CheckFunction
) => (
  router: Router
) => (
  toState: State,
  fromState: State,
  done: DoneFn
): boolean | void | Promise<boolean> => new Promise((
  resolve,
  reject
): void => {
  let subscription: undefined | Subscription;
  const data = permissionService.componentPermissionSubject$.value;
  if (!data) {
    subscription = permissionService.componentPermissionSubject$
      .subscribe((data: null | ComponentPermissionData) => {
        if (data) {
          if (!!subscription) {
            subscription.unsubscribe();
          }
          checkFunction({
            data,
            router,
            toState,
            fromState,
            done,
            resolve,
            reject
          });
        }
      });
  } else {
    checkFunction({
      data,
      router,
      toState,
      fromState,
      done,
      resolve,
      reject
    });
  }
});

export const systemsCheckFunction: CheckFunction = ({
  data,
  toState,
  resolve,
  reject
}) => {
  const { assetManagement } = data;
  if (!!assetManagement) {
    const { params = {} } = toState;
    const { id, url: routeTab } = params;
    const urlList = [ 'principal', 'website' ];
    if (urlList.includes(routeTab)) {
      const name = id
        ? 'assetManagementTabAsset'
        : 'assetManagementTab';
      const params = { routeTab, id };
      reject({ redirect: { name, params } });
    }
    toState.error = 404;
  }
  resolve(true);
};

export const assetManagementCheckFunction: CheckFunction = ({
  data,
  toState,
  resolve,
  reject
}) => {
  const { assetManagement } = data;
  if (!assetManagement) {
    const { params = {} } = toState;
    const { id, routeTab: url } = params;
    const routeTabList = [ 'principal', 'website' ];
    if (routeTabList.includes(url)) {
      const name = id
        ? id === 'add'
          ? 'migratedSystemsAdd'
          : 'migratedSystemsDetails'
        : 'migratedSystemsList';
      const params = { url, id };
      reject({ redirect: { name, params } });
    }
    toState.error = 404;
  }
  resolve(true);
};
