import $ from 'jquery';
import { DataFormat, GroupedDataFormat, IdTextPair, LoadingData } from 'select2';
import { isLoadingData } from '@utilities';

export const templateSelection = (placeholder: string) => (
  state: DataFormat | GroupedDataFormat | LoadingData | IdTextPair
): string | JQuery => {
  const $result = $('<span class="dropdown-user-item" />');
  if (
    (isLoadingData(state) && state.loading)
    || state.id === ''
    || state.id === 'false'
    || state.text.indexOf('<') < 0
  ) {
    return $result.text(state.text);
  }
  $result.addClass('user');
  $result.attr('title', state.text);
  const array = state.text.split('<');
  const fullName = array[0].trim();
  const email = array[1].slice(0, -1);

  const $info = $('<span class="info" />');
  const info = fullName ? fullName : placeholder;
  $info.append(info);
  $info.append(' ');
  const $email = $('<span class="email" />');
  $email.append('<');
  $email.append(email);
  $email.append('>');
  $result.append($info);
  $result.append($email);
  return $result;
};
