import { Column } from 'react-table';
import { DataStorageSelfHosted } from '@services';
import { Header } from '@proliance-ai/design-system';
import { ColumnsOptions } from './getColumns';

const getDataStorageSelfHostedColumnList = ({
  t,
  systemsType
}: ColumnsOptions): Array<Column<DataStorageSelfHosted>> => [
  {
    accessor: 'name',
    width: 'auto',
    disableFilters: true,
    Header: Header({ t, headingKey: systemsType })
  },
  {
    id: 'businessPremise',
    width: 'auto',
    disableFilters: true,
    Header: Header({ t, headingKey: systemsType }),
    accessor: (originalRow: DataStorageSelfHosted) => originalRow.businessPremise.name
  }
];

export default getDataStorageSelfHostedColumnList;
