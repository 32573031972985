import { useEffect, useState } from 'react';
import { Country } from '@proliance-ai/typings';
import { assetManagementService } from '@services';

export const useCountryName = (country: Country): string => {
  const [ countryName, setCountryName ] = useState<string>('');
  useEffect(() => {
    assetManagementService
      .getCountryTitle(country)
      .then((value: string): void => {
        setCountryName(value);
      });
  }, [ country ]);
  return countryName;
};
