import { Column } from 'react-table';
import { Principal, SystemType } from '@services';
import { Header, MergedCell, ValueCell } from '@proliance-ai/design-system';
import { CountryCell, SystemAssetColumnsOptions, SystemAssetModalActionsCell } from '../..';

import styles from '../FormComponent.styl';

export const getPrincipalColumnList = ({
  t,
  systemsType,
  isLoadingState,
  callback
}: SystemAssetColumnsOptions): Array<Column<Principal>> => [
  {
    accessor: 'name',
    width: 'auto',
    disableFilters: true,
    Header: Header({ t, headingKey: systemsType }),
    Cell: ValueCell()
  },
  {
    id: 'address.streetAddress',
    accessor: 'street',
    width: 'auto',
    disableFilters: true,
    Header: Header({ t, headingKey: systemsType }),
    Cell: MergedCell({ columnNameList: [ 'address.streetAddress', 'address.streetNumber' ] })
  },
  {
    accessor: 'address.zip',
    width: 75,
    disableFilters: true,
    Header: Header({ t, headingKey: systemsType })
  },
  {
    accessor: 'address.city',
    width: 'auto',
    disableFilters: true,
    Header: Header({ t, headingKey: systemsType })
  },
  {
    accessor: 'address.country',
    width: 'auto',
    disableFilters: true,
    Header: Header({ t, headingKey: systemsType }),
    Cell: CountryCell
  },
  {
    id: 'actions',
    width: 87,
    disableSortBy: true,
    disableFilters: true,
    Header: Header({ t, headingKey: systemsType }),
    Cell: SystemAssetModalActionsCell({
      t,
      className: styles.right,
      translationKey: 'common:principal.modal.delete',
      systemsType: systemsType as SystemType,
      isLoadingState,
      callback
    })
  }
];
