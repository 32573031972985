import { Observable } from 'rxjs';
import { BusinessPremise, BusinessPremiseData, Collection } from '@proliance-ai/typings';
import { snakeCaseToKebabCase } from '@proliance-ai/utilities';
import { RequestOptions } from '@proliance-ai/design-system';
import { SystemsApiUrl, SystemsEntity, SystemsStatistics, SystemType } from '@services';
import { Api } from '@services/api';

const apiUrl = '/api/survey/system';

class SystemsApiService extends Api {
  public getSystemsStatistics(): Observable<SystemsStatistics> {
    return this.get<SystemsStatistics>({
      url: '/api/survey/company-data/system-count',
      ignoreApiUrl: true
    });
  }

  public getSystemsByType(url: SystemsApiUrl, parameters = {}): Observable<Collection<SystemsEntity>> {
    return this.get<Collection<SystemsEntity>>({
      url,
      parameters
    });
  }

  public createBusinessPremise(body: BusinessPremiseData): Observable<BusinessPremise> {
    return this.post<BusinessPremise>({
      url: 'business_premise',
      body
    });
  }

  public updateBusinessPremise(id: string, body: BusinessPremise): Observable<BusinessPremise> {
    return this.put<BusinessPremise>({
      url: `business_premise/${ id }`,
      body
    });
  }

  public getSystems<S>(systemType: SystemType, id: string): Observable<S> {
    return this.get<S>({
      url: `${ snakeCaseToKebabCase(systemType) }/${ id }`
    });
  }

  public createSystem<D, S>(systemType: SystemType, body: D, options: RequestOptions = {}): Observable<S> {
    return this.post<S>({
      ...options,
      url: snakeCaseToKebabCase(systemType),
      body
    });
  }

  public updateSystem<S>(systemType: SystemType, id: string, body: S): Observable<S> {
    return this.put<S>({
      url: `${ snakeCaseToKebabCase(systemType) }/${ id }`,
      body
    });
  }

  public deleteSystem(systemType: SystemType, id: string): Observable<void> {
    return this.delete<void>({
      url: `api/survey/company-data/${ snakeCaseToKebabCase(systemType) }/${ id }`,
      ignoreApiUrl: true
    });
  }
}

export const systemsApiService = new SystemsApiService(apiUrl);
