import React, { ReactNode } from 'react';
import { TFunction } from 'i18next';
import { i18n } from '@services';
import { MultipleFieldErrors } from 'react-hook-form';

import styles from './ErrorFactory.styl';

export type MessageObject = {
  key: string;
  values: string[];
};
export type Message = string | MessageObject;
type ErrorRenderer = (data: { message: Message; messages?: MultipleFieldErrors }) => ReactNode;
type ErrorRendererFactory = (t: TFunction, fieldName: string, params?: object, valuesTranslationKey?: ((valueKey: string) => string) | string) => ErrorRenderer;

const ErrorFactory: ErrorRendererFactory = (
  t,
  fieldName,
  params = {},
  valuesTranslationKey?: ((valueKey: string) => string) | string
) => ({ message }) => {
  const key = typeof message === 'object'
    ? message.key
    : message;
  const valuesArray = typeof message === 'object'
    ? message.values.map((valueKey: string) => typeof valuesTranslationKey === 'function'
      ? t(valuesTranslationKey(valueKey))
      : t(`${ valuesTranslationKey }.${ valueKey }`))
    : [];
  const values = valuesArray.join(', ');
  const text = i18n.exists(`form:errors.${ key }`)
    ? t(`form:errors.${ key }`, { fieldName, ...params, values })
    : key;
  return (
    <span className={styles.error}>{text}</span>
  );
};

export default ErrorFactory;
