import React, { ReactElement, useEffect } from 'react';
import { Namespace } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { of } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { catchError, take } from 'rxjs/operators';
import { ErrorFactory, modalService, notificationService } from '@proliance-ai/react-ui';
import { useRequiredFields } from '@proliance-ai/yup';
import { Input } from '@proliance-ai/design-system';
import { SystemType, Website, WebsiteData } from '@services';
import { FormComponentProperties } from '@shared/hooks';

import { schema } from './schema';
import styles from '../FormComponent.styl';

const WebsiteFormComponent = ({
  textNamespace,
  formNamespace,
  translationKey,
  entity = {} as Website,
  onClose,
  setForm,
  setIsLoading,
  setIsDirty,
  callback,
  createMethod,
  updateMethod
}: FormComponentProperties<Website, WebsiteData>): ReactElement => {
  const { t } = useTranslation([ textNamespace, formNamespace ] as Namespace);
  const text = t(`${ translationKey }.text`);
  const { id, ...rest } = entity;
  const defaultValues = {
    url: 'https://',
    ...rest
  };

  const form = useForm<WebsiteData>({
    resolver: yupResolver(schema),
    defaultValues
  });
  const {
    getValues,
    register,
    handleSubmit,
    setError,
    formState: {
      dirtyFields,
      errors
    }
  } = form;
  const value = getValues();
  const { getRequiredState } = useRequiredFields<WebsiteData>({
    schema,
    value
  });

  useEffect(() => {
    setIsDirty(!!Object.keys(dirtyFields).length);
  }, [ Object.keys(dirtyFields).length ]);

  const urlInput = (
    <div className="inputWrap">
      <Input
        label={ t(`${ translationKey }.url.label`) }
        required={ getRequiredState('url') }
        placeholder={ t(`${ translationKey }.url.placeholder`) }
        useFormGroup={ false }
        dataAttributesDictionary={ {
          test: { website: 'url' },
          guide: { website: 'url' }
        } }
        { ...register('url') }
      />
      <ErrorMessage
        name="url"
        errors={ errors }
        render={ ErrorFactory(t, t(`${ translationKey }.url.label`)) }
      />
    </div>
  );

  const onSubmit = (body: WebsiteData): void => {
    setIsLoading(true);
    const systemType: SystemType = 'website';
    const isCreate = !id;
    const method = isCreate
      ? createMethod(systemType, body, { suppressDefaultErrorHandler: [ 400 ] })
      : updateMethod(systemType, id, body as Website);
    method
      .pipe(
        take(1),
        catchError((error: AjaxError) => {
          const { message } = error.response;
          setError('url', {
            type: 'server',
            message
          });
          return of(null);
        })
      )
      .subscribe((response: null | Website) => {
        if (!!response) {
          if (typeof callback === 'function') {
            callback();
          }
          const dataAttributesValue = {
            notificationSuccess: isCreate
              ? 'createWebsite'
              : 'updateWebsite'
          };
          const dataAttributesDictionary = {
            test: dataAttributesValue,
            guide: dataAttributesValue
          };
          const textTranslationKey = isCreate
            ? `${ translationKey }.message.createSuccess`
            : `${ translationKey }.message.updateSuccess`;
          notificationService.success({
            namespaces: textNamespace,
            textTranslationKey,
            dataAttributesDictionary
          });
          if (typeof onClose === 'function') {
            onClose();
          }
          modalService.hide();
        }
        setIsLoading(false);
      });
  };

  const onError = (errors: any): void => {
    console.error('onError', errors);
  };
  return (
    <form
      ref={ setForm }
      className={ `form ${ styles.form }` }
      onSubmit={ handleSubmit(onSubmit, onError) }
    >
      <p>{ text }</p>
      <div className="row">
        { urlInput }
      </div>
    </form>
  );
};

export default WebsiteFormComponent;
