import { Column } from 'react-table';
import { Software } from '@services';
import { Header } from '@proliance-ai/design-system';
import { ColumnsOptions } from './getColumns';

const getSoftwareColumnList = ({
  t,
  systemsType
}: ColumnsOptions): Array<Column<Software>> => [
  {
    accessor: 'name',
    width: 'auto' as unknown as number,
    disableFilters: true,
    Header: Header({ t, headingKey: systemsType })
  },
  {
    accessor: 'manufacturer',
    width: 'auto' as unknown as number,
    disableFilters: true,
    Header: Header({ t, headingKey: systemsType })
  }
];

export default getSoftwareColumnList;
