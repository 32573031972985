import { SortingRule } from 'react-table';
import { Observable, of } from 'rxjs';
import { share, take, tap } from 'rxjs/operators';
import { Collection } from '@proliance-ai/typings';
import { ITableState } from '@proliance-ai/design-system';
import { Case, kebabCaseToSnakeCase } from '@proliance-ai/utilities';
import {
  getRequestParametersFromTableParameters,
  SystemsService,
  Systems,
  SystemsApiUrl,
  SystemsEntity,
  SystemsUrl
} from '@services';
import { currentTimeApiService, systemsApiService } from '@services/api';

let currentTime: null | string = null;

const getCurrentTime = (): Observable<string> => currentTime !== null
  ? of(currentTime)
  : currentTimeApiService.getCurrentTime()
    .pipe(
      take(1),
      tap((value: string) => {
        currentTime = value;
      }),
      share()
    );

const transformSortByIdDictionary: Record<string, string> = {
  city: 'address.city',
  country: 'address.country',
  streetAddress: 'address.streetAddress',
  zip: 'address.zip',
  serviceProvider: 'serviceProvider.name',
  businessPremise: 'businessPremise.name'
};
const transformSortBy = (url: string, sortBy?: Array<SortingRule<SystemsEntity>>): undefined | Array<SortingRule<SystemsEntity>> => {
  if (!sortBy || sortBy.length === 0) {
    return sortBy;
  }
  const systemsType = systemsService.getSystemsType(url);
  if (systemsType === Systems.FREELANCER) {
    if (sortBy[0].id === 'name') {
      return [ {
        id: 'lastName',
        desc: sortBy[0].desc
      } ];
    }
  }
  return [ {
    id: transformSortByIdDictionary[sortBy[0].id] || sortBy[0].id,
    desc: sortBy[0].desc
  } ];
};
const getSystemsByType = (
  value: string,
  tableState: Partial<ITableState<SystemsEntity>> = {}
): Observable<null | Collection<SystemsEntity>> => {
  const url = getSystemsApiUrl(value);
  if (!url) {
    console.error(`getSystemsByType: getSystemsApiUrl(${ value }) is null`); // TODO handle error
    return of(null);
  }
  const sortBy = transformSortBy(value, tableState.sortBy);
  return systemsApiService.getSystemsByType(url, getRequestParametersFromTableParameters({ ...tableState, sortBy }));
};

const getSystemsApiUrl = (value: string): null | SystemsApiUrl => {
  const type = getSystemsType(value);
  if (type === null) {
    return type;
  }
  return type.toLowerCase() as SystemsApiUrl;
};

const getSystemsUrl = (value: string): null | SystemsUrl => {
  const type = getSystemsType(value);
  if (type === null) {
    return type;
  }
  return type
    .toLowerCase()
    .split('_')
    .join('-') as SystemsUrl;
};

const getSystemsType = (value?: string): null | Systems => {
  if (!value) {
    return null;
  }
  const systemsType = kebabCaseToSnakeCase(value, Case.upper);
  if (systemsType in Systems) {
    return systemsType as Systems;
  }
  return null;
};

export const systemsService: SystemsService = {
  getSystemsStatistics: systemsApiService.getSystemsStatistics.bind(systemsApiService),
  getCurrentTime,
  getSystemsByType,
  getSystemsType,
  getSystemsApiUrl,
  getSystemsUrl,
  createBusinessPremise: systemsApiService.createBusinessPremise.bind(systemsApiService),
  updateBusinessPremise: systemsApiService.updateBusinessPremise.bind(systemsApiService),
  getSystems: systemsApiService.getSystems.bind(systemsApiService),
  createSystem: systemsApiService.createSystem.bind(systemsApiService),
  updateSystem: systemsApiService.updateSystem.bind(systemsApiService),
  deleteSystem: systemsApiService.deleteSystem.bind(systemsApiService)
};
