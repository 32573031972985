// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".form-FormComponent_Pcven{margin:20px 24px 0}.dpoBlock-FormComponent_raTEC{padding-top:16px}.dpoAddressForm-FormComponent_P5qC6{padding-top:24px}.toggleButton-FormComponent_rrhLI{margin-top:0}.right-FormComponent_NnjPJ{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-justify-content:flex-end;-ms-justify-content:flex-end;justify-content:flex-end;-webkit-box-align:center;-webkit-align-items:center;-ms-align-items:center;align-items:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "form-FormComponent_Pcven",
	"dpoBlock": "dpoBlock-FormComponent_raTEC",
	"dpoAddressForm": "dpoAddressForm-FormComponent_P5qC6",
	"toggleButton": "toggleButton-FormComponent_rrhLI",
	"right": "right-FormComponent_NnjPJ"
};
export default ___CSS_LOADER_EXPORT___;
