import { Header } from '@proliance-ai/design-system';
import { SystemAssetColumnsOptions, SystemAssetModalActionsCell } from '../..';
import { SystemType } from '@services';

import styles from '../FormComponent.styl';

export const getWebsiteColumnList = ({
  t,
  systemsType,
  isLoadingState,
  callback
}: SystemAssetColumnsOptions) => [
  {
    accessor: 'url',
    width: 'auto',
    disableFilters: true,
    Header: Header({ t, headingKey: systemsType })
  },
  {
    id: 'actions',
    width: 87,
    disableSortBy: true,
    disableFilters: true,
    Header: Header({ t, headingKey: systemsType }),
    Cell: SystemAssetModalActionsCell({
      t,
      className: styles.right,
      translationKey: 'common:website.modal.delete',
      systemsType: systemsType as SystemType,
      isLoadingState,
      callback
    })
  }
];
