import { useState } from 'react';
import { Observable, of } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { catchError, finalize, take } from 'rxjs/operators';
import {
  AssignTeamMemberData,
  InviteTeamMemberData,
  isAssignTeamMemberData,
  TeamMemberData,
  User
} from '@proliance-ai/typings';
import { loadingIndicatorService } from '@proliance-ai/design-system';
import { notificationService, modalService } from '@proliance-ai/react-ui';
import {
  permissionService,
  teamService,
  userService
} from '@services';
import { getUserName } from '@shared/hooks/utilities';
import {
  UseMainEditorManagement,
  UseMainEditorManagementOptions,
  UseMainEditorManagementReturn
} from './useMainEditorManagement.typings';

export const useMainEditorManagement: UseMainEditorManagement = (options: UseMainEditorManagementOptions): UseMainEditorManagementReturn => {
  const {
    user = null,
    callback,
    successNotificationOptions
  } = options;
  const userId = user?.id;

  const [ disabled, setDisabled ] = useState<boolean>(true);
  const [ emailErrorMessage, setEmailErrorMessage ] = useState<string | null>(null);

  const assignTeamMember = (data: AssignTeamMemberData, updatePermissions: boolean): void => {
    loadingIndicatorService.show();
    const { teamArea, userId } = data;
    teamService
      .assignTeamMember(teamArea, userId)
      .pipe(
        take(1),
        catchError((): Observable<null> => of(null)),
        finalize(() => loadingIndicatorService.hide())
      )
      .subscribe((result: null | User): void => {
        if (result !== null) {
          if (updatePermissions) {
            permissionService.assignPermissionData().subscribe();
          }
          if (typeof successNotificationOptions !== 'undefined') {
            notificationService.success({
              ...successNotificationOptions,
              textTranslationParams: { user: getUserName(result) }
            });
          }
          if (typeof callback === 'function') {
            callback();
          }
          modalService.hide();
          loadingIndicatorService.hide();
        }
      });
  };

  const inviteTeamMember = (userData: InviteTeamMemberData, updatePermissions: boolean): void => {
    loadingIndicatorService.show();
    teamService
      .inviteTeamMember(userData)
      .pipe(
        take(1),
        catchError(
          (error: AjaxError): Observable<null> => {
            const { response: { message } } = error;
            setEmailErrorMessage(message);
            setDisabled(false);
            return of(null);
          }
        ),
        finalize(() => loadingIndicatorService.hide())
      )
      .subscribe((result: null | User) => {
        if (result !== null) {
          if (updatePermissions) {
            permissionService.assignPermissionData().subscribe();
          }
          setEmailErrorMessage(null);
          setDisabled(false);
          if (typeof successNotificationOptions !== 'undefined') {
            notificationService.success({
              ...successNotificationOptions,
              textTranslationParams: { user: getUserName(result) }
            });
          }
          if (typeof callback === 'function') {
            callback();
          }
          modalService.hide();
          loadingIndicatorService.hide();
        }
      });
  };

  const addTeamMember = (data: TeamMemberData): void => {
    const { id, email } = userService.userSubject$.value!;
    const isCurrentUserDismissed: boolean = userId === id;
    if (isAssignTeamMemberData(data)) {
      const isCurrentUserAssigned: boolean = data?.userId === id;
      const updatePermissions = isCurrentUserAssigned || isCurrentUserDismissed;
      assignTeamMember(data, updatePermissions);
    } else {
      const isCurrentUserAssigned: boolean = data?.email === email;
      const updatePermissions = isCurrentUserAssigned || isCurrentUserDismissed;
      inviteTeamMember(data, updatePermissions);
    }
  };

  return {
    addTeamMember,
    disabledState: [ disabled, setDisabled ],
    emailErrorMessageState: [ emailErrorMessage, setEmailErrorMessage ]
  };
};
