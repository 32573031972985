import { Observable, of, throwError } from 'rxjs';
import { Case, kebabCaseToSnakeCase } from '@proliance-ai/utilities';
import { getAcceptedRouteTabList, ResolverData } from '@router';
import { TabsRouteList } from '@router/data';

export const getRouteTab = <T extends string>(data: ResolverData): null | T => data.parameter.routeTab
  ? kebabCaseToSnakeCase(data.parameter.routeTab, Case.upper) as T
  : null;

export const getIsAcceptedTab = <T extends string>(routeTab: null | T, routeName: TabsRouteList, data: ResolverData): boolean => {
  if (!routeTab) {
    return false;
  }
  const acceptedRouteTabList: T[] = getAcceptedRouteTabList<T>(routeName, data.permission);
  return acceptedRouteTabList.includes(routeTab);
};

export const getAcceptedRouteTab = <T extends string>(routeName: TabsRouteList, data: ResolverData): null | T => {
  const routeTab = getRouteTab<T>(data);
  if (!routeTab) {
    return routeTab;
  }
  const isAcceptedTab = getIsAcceptedTab<T>(routeTab, routeName, data);
  return isAcceptedTab
    ? routeTab
    : null;
};

export const routeTabHandler = <T extends string>(routeName: TabsRouteList, data: ResolverData): Observable<null | T> => {
  const routeTab = getRouteTab<T>(data);
  if (!routeTab) {
    return of(routeTab);
  }
  const isAcceptedTab = getIsAcceptedTab<T>(routeTab, routeName, data);
  return isAcceptedTab
    ? of(routeTab)
    : throwError({ status: 404 });
};
