import { setLocale } from 'yup';

setLocale({
  mixed: {
    oneOf: ({ resolved }) => ({
      key: 'oneOf',
      values: resolved
    })
  }
});
// TODO move to DS Yup
