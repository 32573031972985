import React from 'react';
import { TFunction } from 'i18next';
import { CellProps } from 'react-table';

import styles from './AuthorCell.styl';

export interface IAuthorCellOptions {
  t: TFunction;
  className?: string;
}

export const AuthorCell = <T extends {}>(options: IAuthorCellOptions) => (props: CellProps<T>) => {
  const { t, className } = options;
  if (!props.cell.value) {
    return  (
      <div className={className}>
        {t('table.cell.author.system')}
      </div>
    );
  }

  const { admin, name, onBehalf } = props.cell.value;
  const value = name
    ? name
    : admin
      ? t('table.cell.author.admin')
      : null;
  const onBehalfElement = onBehalf
    ? (<div className={styles.onBehalf}>{t('table.cell.author.onBehalf', { onBehalf })}</div>)
    : null;
  return onBehalfElement
    ? (
      <div className={className}>
        {value} {onBehalfElement}
      </div>
    )
    : (
      <div className={className}>
        {value}
      </div>
    );
};
