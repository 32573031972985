import { object } from 'yup';
import { file, text } from '@proliance-ai/yup';

export const schema = object()
  .shape({
    type: text().nullable().required('chooseOption'),
    topic: text().nullable()
      .when(
        'type',
        {
          is: 'CONSULTING_REQUEST',
          then: (schema) => schema.required('chooseOption')
        }
      ),
    message: text().nullable().required('required'),
    attachments: file()
      .acceptedTypes([ 'JP(E)G', 'PNG', 'WEBP', 'TXT', 'PDF', 'DOC(X)' ])
      .maxUploadSize(20)
  });
