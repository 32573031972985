import React, { FC, memo, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FunctionalArea, DictionaryResponse, Locale, TranslationInfo, User } from '@proliance-ai/typings';
import { Chip } from '@proliance-ai/react-ui';
import { Case, snakeCaseToKebabCase } from '@proliance-ai/utilities';

import styles from './SystemDeleteErrorModalContent.styl';

export enum TaskType {
  ACADEMY = 'ACADEMY',
  ACADEMY_INVITE_EMPLOYEE = 'ACADEMY_INVITE_EMPLOYEE',
  DPO_NOMINATION = 'DPO_NOMINATION',
  DPO_PRIVACY_POLICY = 'DPO_PRIVACY_POLICY',
  DPO_NOTIFICATION = 'DPO_NOTIFICATION',
  DEFINE_TEAM = 'DEFINE_TEAM',
  PROCESS_SELECTION = 'PROCESS_SELECTION',
  ADDITIONAL_PROCESS_SELECTION = 'ADDITIONAL_PROCESS_SELECTION',
  PROCESS_CONTEXT = 'PROCESS_CONTEXT',
  AUDIT_CONCEPT = 'AUDIT_CONCEPT',
  PROCESS_DETAIL = 'PROCESS_DETAIL,',
  ACTION = 'ACTION',
}

export type TaskTypeType = keyof typeof TaskType;

export interface ISurveyUrl {
  id: string;
  type: TaskTypeType;
}

export interface ICompanyDataEntity {
  id: string;
  name: TranslationInfo;
  surveyUrl: ISurveyUrl;
}

export interface IActionCompanyData extends ICompanyDataEntity {
  assignee: User;
  functionalArea: FunctionalArea;
  readonly: boolean;
  draft: boolean;
  dpoAssigned: boolean;
}

export enum Status {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  DELETED = 'DELETED',
}

export interface IAuditConceptCompanyData extends ICompanyDataEntity {
  assignee: User;
  compliant: boolean;
  readonly: boolean;
  draft: boolean;
  status: Status;
  functionalArea: FunctionalArea;
  tomStatus: boolean;
  dpoAssigned: boolean;
  actions: IActionCompanyData[];
}

export interface IClusterDataProcess extends ICompanyDataEntity {
  activeAssignment: boolean;
  assignee?: User;
  closedAudits: number;
  compliant: boolean;
  readonly: boolean;
  draft: boolean;
  status?: Status;
  subProcesses?: IClusterDataProcess[];
  audits?: IAuditConceptCompanyData[];
  totalAudits: number;
}

export interface ICustomProcessesClusterData {
  custom: boolean;
  processes: IClusterDataProcess[];
}

export interface IClusterData extends ICustomProcessesClusterData {
  clusterId: string;
  clusterName: TranslationInfo;
}

export interface IAuditConceptCompanyData extends ICompanyDataEntity {
  assignee: User;
  compliant: boolean;
  readonly: boolean;
  draft: boolean;
  status: Status;
  functionalArea: FunctionalArea;
  tomStatus: boolean;
  dpoAssigned: boolean;
  actions: IActionCompanyData[];
}

export interface IActionCompanyData extends ICompanyDataEntity {
  assignee: User;
  functionalArea: FunctionalArea;
  readonly: boolean;
  draft: boolean;
  dpoAssigned: boolean;
}

export interface ITomCompanyData extends ICompanyDataEntity {
  tomTopic: DictionaryResponse<TranslationInfo>;
  closedAudits: number;
  totalAudits: number;
  compliant: boolean;
  audits: IAuditConceptCompanyData[];
}

interface Error {
  processes: {
    [key in FunctionalArea]: IClusterData[]
  };
  tomAudits: ITomCompanyData[];
}

interface Properties {
  translationKey: string;
  error: Error;
}

export enum TaskTypeEncode {
  DPO_NOMINATION = 'dpoNomination',
  DPO_PRIVACY_POLICY = 'dpoPrivacyPolicy',
  DPO_NOTIFICATION = 'dpoNotification',
  DEFINE_TEAM = 'defineTeam',
  PROCESS_SELECTION = 'processSelection',
  ADDITIONAL_PROCESS_SELECTION = 'additionalProcessSelection',
  PROCESS_CONTEXT = 'processContext',
  AUDIT_CONCEPT = 'auditConcept',
  ACTION = 'action',
  PROCESS_DETAIL = 'processDetail',
  ACADEMY = 'academy',
  ACADEMY_INVITE_EMPLOYEE = 'academyInviteEmployee',
}

export const encodeTaskType = (type: TaskTypeType) => TaskTypeEncode[type];

const SystemDeleteErrorModalContent: FC<Properties> = ({
  translationKey,
  error
}): ReactElement => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const locale = language as Locale;
  const delimiter = ' | ';
  const { processes, tomAudits } = error;
  const functionalAreaList = Object.keys(processes) as FunctionalArea[];
  const processesElementList = functionalAreaList
    .map((functionalArea: FunctionalArea) => {
      const functionalAreaText = t(`common:functionalArea.${ functionalArea }`);
      return processes[functionalArea]
        .map((cluster: IClusterData) => {
          const { clusterName, custom, processes } = cluster;
          const clusterNameText = custom
            ? t(`${ translationKey }.custom`)
            : clusterName[locale];
          return processes
            .map((process: IClusterDataProcess) => {
              const { id: processId, name, surveyUrl: { id } } = process;
              const processesPart = custom
                ? 'processes/custom'
                : 'task/system-assignment';
              const functionalAreaPart = custom
                ? `${ snakeCaseToKebabCase(functionalArea, Case.lower) }/`
                : '';
              const href = `/${ processesPart }/${ functionalAreaPart }${ id }`;
              const content = `${ functionalAreaText }${ delimiter }${ clusterNameText }${ delimiter }${ name[locale] }`;
              return (
                <a
                  key={ processId }
                  href={ href }
                >
                  <Chip
                    preset="primary"
                    size="small"
                    title={ content }
                    interactive={ true }
                    content={ content }
                  />
                </a>
              );
            });
        });
    });
  const processesElement = processesElementList.length
    ? (
      <>
        <p className={ styles.heading }>{ t(`${ translationKey }.processes`) }</p>
        <div className={ styles.box }>
          { processesElementList }
        </div>
      </>
    )
    : null;

  const tomAuditsElementList = tomAudits
    .map((tomAuditsElement: ITomCompanyData) => {
      const tomTopicText = tomAuditsElement.tomTopic.title[locale];
      const { audits } = tomAuditsElement;
      return audits
        .map((audit: IAuditConceptCompanyData) => {
          const { id: auditId, name, surveyUrl: { id, type } } = audit;
          const typePart = encodeTaskType(type);
          const href = `/task/${ typePart }/${ id }`;
          const content = `${ tomTopicText }${ delimiter }${ name[locale] }`;
          return (
            <a
              key={ auditId }
              href={ href }
            >
              <Chip
                preset="primary"
                size="small"
                title={ content }
                interactive={ true }
                content={ content }
              />
            </a>
          );
        });
    });
  const tomAuditsElement = tomAuditsElementList.length
    ? (
      <>
        <p className={ styles.heading }>{ t(`${ translationKey }.tom`) }</p>
        <div className={ styles.box }>
          { tomAuditsElementList }
        </div>
      </>
    )
    : null;
  return (
    <>
      <p className={ styles.text }>{ t(`${ translationKey }.text`) }</p>
      { processesElement }
      { tomAuditsElement }
    </>
  );
};

export default memo(SystemDeleteErrorModalContent);
