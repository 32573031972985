import React, { FC, useState, useEffect, Dispatch, SetStateAction, ChangeEvent } from 'react';
import { Namespace, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import { DataFormat, QueryOptions } from 'select2';
import {
  AssignTeamMemberData,
  AvailableUserContext,
  ComponentPermissionData,
  InvitedTeamMemberData,
  TeamArea,
  TeamMemberData,
  User
} from '@proliance-ai/typings';
import { defaultDropdownPageSize } from '@proliance-ai/design-system';
import {
  teamService
} from '@services';
import { UserDropdown } from '@shared/components';
import { userFormat } from '@shared/components/ui/UserDropdown/userFormat';
import { ErrorFactory } from '@shared/form';
import { InviteUserForm } from '@shared/ui';

import { getSchema } from './schema';
import styles from './TeamModal.styl';

interface TeamModalProperties {
  namespace: Namespace;
  descriptionKey?: string;
  permission: ComponentPermissionData;
  context: AvailableUserContext;
  teamArea: TeamArea;
  setForm: Dispatch<SetStateAction<HTMLFormElement | null>>;
  user: null | User;
  setDisabled: Dispatch<boolean>;
  emailErrorMessage?: null | string;
  callback: (data: TeamMemberData) => void;
}

const TeamModal: FC<TeamModalProperties> = ({
  namespace,
  descriptionKey = 'modal.detail',
  permission,
  context,
  teamArea,
  setForm,
  user,
  setDisabled,
  emailErrorMessage,
  callback
}) => {
  const { t } = useTranslation([ namespace as string, 'form' ]);

  const title = t(`common:functionalArea.${ teamArea }`);

  const [ data, setData ] = useState<DataFormat[]>([]);
  const [ initialUserId ] = useState<undefined | number>(user?.id);

  const addItemId = 0;
  const form = useForm({
    resolver: yupResolver(getSchema(addItemId)),
    defaultValues: {
      teamArea,
      userId: null,
      email: '',
      firstName: '',
      lastName: ''
    } as InvitedTeamMemberData
  });

  const {
    formState: { errors },
    getValues,
    setValue,
    watch,
    register,
    handleSubmit,
    setError,
    clearErrors
  } = form;

  useEffect(() => {
    if (user) {
      setValue('userId', user.id);
      setData([
        {
          id: user.id,
          text: userFormat(user),
          disabled: user.locked
        }
      ]);
    }
    return () => {
      setValue('userId', null);
      setData([]);
    };
  }, [ user ]);

  useEffect(() => {
    if (emailErrorMessage === null) {
      clearErrors('email');
    } else {
      setError(
        'email',
        {
          type: 'backend',
          message: emailErrorMessage
        },
        { shouldFocus: true }
      );
    }
  }, [ emailErrorMessage ]);

  const onSubmitHandler = (value: InvitedTeamMemberData): void => {
    const { userId: currentUserId, teamArea: currentFunctionalArea, ...userData } = value;
    const payload = currentUserId === addItemId
      ? { teamArea, ...userData } as InvitedTeamMemberData
      : ({
        userId: currentUserId,
        teamArea: currentFunctionalArea
      } as AssignTeamMemberData);
    callback(payload);
  };

  const userId = watch('userId');
  const { name, onBlur, onChange } = register('userId');
  const onChangeUserId = (event: ChangeEvent<HTMLSelectElement>): void => {
    const id = event.currentTarget.value;
    setDisabled(id === null || `${ id }` === `${ initialUserId }`);
    onChange(event);
  };
  const addItem: undefined | DataFormat = permission.newPersonAdditionTeam?.write
    ? {
      id: addItemId,
      text: t('modal.create')
    }
    : undefined;
  const dataSource = teamService.getAvailableUserList.bind(teamService);
  const ajaxData = (params: QueryOptions) => {
    return {
      page: params.page || 1,
      pageSize: defaultDropdownPageSize,
      sortField: 'firstName',
      sortDirection: 'asc',
      search: params.term,
      context,
      teamArea: teamArea
    };
  };

  const onError = (error: unknown): void => {
    console.error(error);
  };

  return (
    <div className={ styles.modal }>
      <p className={ styles.text }>{ t(descriptionKey, { title }) }</p>
      <p className={ styles.info }>{ t('modal.info') }</p>
      <form
        ref={ setForm }
        onSubmit={ handleSubmit(onSubmitHandler, onError) }
      >
        <div className="row">
          <div className="inputWrap">
            <UserDropdown
              label={ t('form:user.label') }
              placeholder="form:user.placeholder"
              namespace={ namespace }
              addItem={ addItem }
              ajaxData={ ajaxData }
              dataSource={ dataSource }
              data={ data }
              useFormGroup={ false }
              name={ name }
              value={ getValues('userId') }
              onBlur={ onBlur }
              onChange={ onChangeUserId }
            />
            <ErrorMessage
              errors={ errors }
              name="userId"
              render={ ErrorFactory(t, t('form:userId')) }
            />
          </div>
        </div>
        <InviteUserForm
          show={ `${ userId }` === `${ addItemId }` }
          form={ form }
        />
      </form>
    </div>
  );
};

export default TeamModal;
