// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal-TeamModal_RN239,.info-TeamModal_NyZ9q,.text-TeamModal_kagP2{font-size:14px;padding:0;margin-top:0}.text-TeamModal_kagP2{color:#1b143f}.info-TeamModal_NyZ9q{color:#797979}.error-TeamModal_hD3AM{position:relative;min-height:15px;margin:5px 0;font-size:12px;line-height:12px;letter-spacing:.26px;word-break:break-word;color:#de283f}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "modal-TeamModal_RN239",
	"info": "info-TeamModal_NyZ9q",
	"text": "text-TeamModal_kagP2",
	"error": "error-TeamModal_hD3AM"
};
export default ___CSS_LOADER_EXPORT___;
