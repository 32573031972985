import { Column } from 'react-table';
import { AssociatedCompany } from '@services';
import { Header, ValueCell } from '@proliance-ai/design-system';
import { MergedCell } from '@proliance-ai/design-system';
import { CountryCell } from '@shared/hooks';
import { ColumnsOptions } from './getColumns';

const getAssociatedCompaniesColumnList = ({
  t,
  systemsType
}: ColumnsOptions): Array<Column<AssociatedCompany>> => [
  {
    accessor: 'name',
    width: 'auto' as unknown as number,
    disableFilters: true,
    Header: Header({ t, headingKey: systemsType }),
    Cell: ValueCell()
  },
  {
    id: 'streetAddress',
    accessor: 'street',
    width: 'auto' as unknown as number,
    disableFilters: true,
    Header: Header({ t, headingKey: systemsType }),
    Cell: MergedCell({ columnNameList: [ 'street', 'houseNumber' ] })
  },
  {
    accessor: 'zip',
    width: 'auto' as unknown as number,
    disableFilters: true,
    Header: Header({ t, headingKey: systemsType })
  },
  {
    accessor: 'city',
    width: 'auto' as unknown as number,
    disableFilters: true,
    Header: Header({ t, headingKey: systemsType })
  },
  {
    accessor: 'country',
    width: 'auto' as unknown as number,
    disableFilters: true,
    Header: Header({ t, headingKey: systemsType }),
    Cell: CountryCell
  }
];

export default getAssociatedCompaniesColumnList;
