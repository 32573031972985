import i18n from 'i18next';
import { tokenService } from '@proliance-ai/design-system';
import { ChoicesRestful } from '@shared/surveyJs/reexport';

interface Options {
  request: XMLHttpRequest;
}

ChoicesRestful.onBeforeSendRequest = (
  sender: ChoicesRestful,
  options: Options
) => {
  sender.allowEmptyResponse = true;
  options.request.setRequestHeader(
    'Authorization',
    `Bearer ${ tokenService.getToken() }`
  );
  options.request.setRequestHeader(
    'Accept-language',
    i18n.language
  );
};
