import { Callback, LocaleType, TranslationInfo } from '@proliance-ai/typings';
import { leadingCharacter } from '@proliance-ai/utilities';
import { configurationService, removeClass } from '@proliance-ai/design-system';
import { IProperty, WidgetEditorLocalization, WidgetLocalization } from '../interfaces';
import { WidgetAbstractClass } from '../widget.abstract.class';
import { addClass } from '../common';
import { Question } from '@shared/surveyJs/reexport';

import { faLink } from '@fortawesome/pro-regular-svg-icons';
import './styles.styl';

export interface IQuestionLink extends Question {
  title: string;
  url: string;
  redirectUrl: string;
  buttonText: TranslationInfo;
}

const modalData: Record<string, string> = {
  'systems/business-premise/add': 'showBusinessPremiseModal',
  'systems/principal/add': 'showPrincipalModal',
  'systems/website/add': 'showWebsiteModal',
  DataStorage: 'showDataStorageModal',
  Software: 'showSoftwareModal',
  ThirdParty: 'showThirdPartyModal'
};

export default class LinkWidget extends WidgetAbstractClass<IQuestionLink> {
  public hidePropertyNameList: string[] = [
    'name',
    'description',
    'isRequired',
    'readOnly',
    'autocompleteAttr',
    'nameAttr',
    'requiredIf',
    'hideNumber',
    'titleLocation',
    'descriptionLocation',
    'valueName',
    'defaultValue',
    'correctAnswer',
    'useDisplayValuesInTitle',
    'requiredErrorText',
    'validators'
  ];

  public customPropertiesList: IProperty[] = [
    {
      type: 'text',
      name: 'buttonText',
      isLocalizable: true,
      category: 'general',
      visibleIndex: 1
    },
    {
      type: 'text',
      name: 'url',
      default: '',
      category: 'general',
      visibleIndex: 2
    },
    {
      type: 'text',
      name: 'redirectUrl',
      default: '',
      category: 'general',
      visibleIndex: 3
    }
  ];

  public surveyLocalization: WidgetLocalization = {
    defaultButtonText: {
      en: 'Link with redirect',
      de: 'Link mit Weiterleitung'
    }
  };

  public editorLocalization: WidgetEditorLocalization = {
    qt: {
      [this.name]: {
        en: 'Link',
        de: 'Link'
      }
    },
    p: {
      buttonText: {
        en: 'Button text',
        de: 'Schaltflächentext'
      },
      url: {
        en: 'URL',
        de: 'URL'
      },
      redirectUrl: {
        en: 'Redirect URL',
        de: 'URL umleiten'
      }
    }
  };

  protected alternativeNameList: string[] = [ 'dselink' ];

  protected classNameDictionary: Record<string, string> = {
    link: 'link-widget'
  };

  constructor() {
    super({
      name: 'link',
      iconName: 'link',
      icon: faLink
    });
  }

  public afterRender(question: IQuestionLink, element: HTMLElement): void {
    super.afterRender(question, element);
  }

  public renderer(question: IQuestionLink, element: HTMLElement): void {
    const link = document.createElement('A');
    addClass(link, this.classNameDictionary.link);
    element.appendChild(link);
    this.onChangeButtonText(question, element);
    this.onChangeReadOnly(question, element, null);
  }

  public onChangeButtonText(question: IQuestionLink, element: HTMLElement): void {
    const link = this.getElement('link', element);
    if (!link) {
      return;
    }
    const locale = this.getCurrentLocale(question) as LocaleType;
    link.innerText = question.buttonText
      ? question.buttonText as unknown as string
      : this.surveyLocalization.defaultButtonText[locale];
  }

  public onChangeReadOnly(question: IQuestionLink, element: HTMLElement, _options: any): void {
    const link = this.getElement('link', element);
    if (!link) {
      return;
    }
    const { url } = question;
    const getMethod = (url: string): undefined | Callback => {
      const modalKey: undefined | keyof typeof modalData = (Object.keys(modalData) as Array<keyof typeof modalData>)
        .find((key: string): string | false => url.includes(key)
          ? key
          : false
        );
      if (!modalKey) {
        return;
      }
      const methodKey = modalData[modalKey];
      const modalDictionary = (question.survey as any).modalDictionary || {};
      return modalDictionary[methodKey];
    };
    const method = getMethod(url);
    if (typeof method === 'function') {
      const callback = (): void => {
        method();
      };
      if (question.isReadOnly) {
        link.removeEventListener('click', callback);
      } else {
        link.addEventListener('click', callback);
      }
    } else {
      if (question.isReadOnly) {
        link.removeAttribute('href');
      } else {
        link.setAttribute('href', this.getHref(question));
      }
    }
    if (question.isReadOnly) {
      addClass(link, 'read-only');
    } else {
      removeClass(link, 'read-only');
    }
  }

  protected onPropertyChangedHandler(question: IQuestionLink, element: HTMLElement, options: any): void {
    super.onPropertyChangedHandler(question, element, options);
    if (options.name === 'buttonText') {
      this.onChangeButtonText(question, element);
    }
  }

  private getHref(question: IQuestionLink): string {
    const url = question.url
      ? leadingCharacter(question.url, '/')
      : '/';
    const redirectUrnParameterName = configurationService.getRedirectUrnParameterName();
    const redirectUrl = question.redirectUrl
      ? question.redirectUrl
      : window.location.pathname;
    return `${ url }?${ redirectUrnParameterName }=${ redirectUrl }`;
  }
}
