import { boolean, object } from 'yup';
import { email, getAddressSchema, phone, text } from '@proliance-ai/yup';

const getBooleanValue = (value?: string | boolean): undefined | boolean => typeof value === 'string'
  ? JSON.parse(value)
  : value;

const address = getAddressSchema();
const addressOptional = getAddressSchema({ required: false });
const getDpoSchema = (required: boolean) => object()
  .shape({
    name: required
      ? text().required('required')
      : text(),
    email: required
      ? email().required('required')
      : email(),
    phoneNumber: required
      ? phone({ required: true })
      : phone({ optional: true }),
    identicalAddress: required
      ? boolean().nullable().required('required')
      : boolean().nullable(),
    address: object()
      .when(
        'identicalAddress',
        (values, schema) => {
          const [ identicalAddress ] = values;
          const identicalAddressValue = getBooleanValue(identicalAddress);
          return identicalAddressValue === false && required
            ? schema.shape(address.fields).required('required')
            : schema.shape(addressOptional.fields).notRequired();
        })
  });
export const schema = object()
  .shape({
    name: text().required('required'),
    legalForm: text().required('required'),
    address,
    dpoPresent: boolean().required('required'),
    dpo: object()
      .when(
        'dpoPresent',
        (values, schema) => {
          const [ dpoPresent ] = values;
          const dpoPresentValue = getBooleanValue(dpoPresent);
          const childSchema = dpoPresentValue
            ? getDpoSchema(true)
            : getDpoSchema(false);
          return schema.shape(childSchema.fields);
        }
      )
  });
