import { Observable } from 'rxjs';
import {
  Collection,
  Comment,
  CommentData,
  CommentRequestParameters
} from '@proliance-ai/typings';
import { Api } from '@services/api';

const apiUrl: string = '/api/survey/asset/comment';

class CommentApiService extends Api {
  public getCommentCollection(parameters: CommentRequestParameters): Observable<Collection<Comment>> {
    return this.get<Collection<Comment>>({
      parameters
    });
  }

  public createComment(commentData: CommentData): Observable<Comment> {
    const { assetId, ...body } = commentData;
    const parameters = { assetId };
    return this.post<Comment>({
      parameters,
      body
    });
  }

  public updateComment(commentData: Comment): Observable<null | Comment> {
    return this.patch<Comment>({
      body: commentData
    });
  }

  public deleteComment(commentId: string): Observable<null> {
    return this.delete<null>({
      url: commentId
    });
  }
}

export const commentApiService = new CommentApiService(apiUrl);
