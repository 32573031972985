import { ItemValue, Model, PageModel, Question, QuestionDropdownModel } from '@shared/surveyJs/reexport';
import { Component, Dispatch, SetStateAction } from 'react';
import { Callback } from '@proliance-ai/typings';
import { ISurveyResult, ISurveyTemplate } from '@services';
import { WithTranslation } from 'react-i18next';
import { Router } from 'router5';

export enum SurveyStatus {
  running = 'RUNNING',
  completed = 'COMPLETED',
}

export interface IElementOptions {
  htmlElement: HTMLElement;
  question: Question;
}

export interface IOwnProps {
  surveyRef?: Dispatch<SetStateAction<Component | undefined>>;
  template: ISurveyTemplate;
  result: ISurveyResult;
  params?: Record<string, string>;
  complete?: (survey: Model) => void;
  modalDictionary?: Record<string, Callback>;
}
export type SurveyViewProperties = IOwnProps & WithTranslation & {router: Router};

export interface ISurveyViewState {
  id: string;
  draft: boolean;
  surveyResultId: string;
  params: Record<string, string>;
  defaultRedirectDelay: number;
  questionsViewModel: Model;
  isErrorsShow: boolean;
}

export interface ISurveyOptions {
  allowComplete: boolean;
  complete: (survey: Model) => void;
}

export interface ISurveyValidateOptions {
  page: PageModel;
  errors: [];
  questions: Question[];
}

export interface ISurveyPropertyOptions<T> {
  name: string;
  newValue: T;
  oldValue: T;
}

export interface ISurveyPageVisibleOptions {
  page: PageModel;
  visible: boolean;
}

export interface IOnLoadChoicesFromServerOptions {
  question: QuestionDropdownModel;
  choices: ItemValue[];
  serverResult: any[];
}
