import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@proliance-ai/design-system';
import { CloseModalButton, modalService } from '@proliance-ai/react-ui';
import { UseTeamUserModal, UseTeamUserModalOptions, UseTeamUserModalReturn } from './useTeamUserModal.typings';
import { TeamModal } from './components';

export const useTeamUserModal: UseTeamUserModal = (options: UseTeamUserModalOptions): UseTeamUserModalReturn => {
  const {
    namespace,
    titleKey = 'modal.title',
    descriptionKey = 'modal.detail',
    context,
    permission,
    teamArea,
    disabledState,
    user = null,
    emailErrorMessageState,
    callback
  } = options;
  const { t } = useTranslation(namespace);

  const [ emailErrorMessage, setEmailErrorMessage ] = emailErrorMessageState;
  const [ form, setForm ] = useState<null | HTMLFormElement>(null);
  const [ disabled, setDisabled ] = disabledState;

  const getActionsElement = (): ReactElement => {
    const submit = (): void => {
      if (form !== null) {
        form.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
      }
    };
    const closeButton = <CloseModalButton />;

    return (
      <>
        { closeButton }
        <Button
          preset="primary"
          dataAttributesDictionary={ {
            test: { button: 'save' },
            guide: { button: 'save' }
          } }
          text={ t('common:button.save') }
          disabled={ disabled }
          onClick={ submit }
        />
      </>
    );
  };

  const showModal = (): void => {
    const title = t(`common:teamArea.${ teamArea }`);
    setEmailErrorMessage(null);
    modalService.show({
      id: 'teamEdit',
      heading: {
        title: t(titleKey, { title })
      },
      content: {
        contentElement: (
          <TeamModal
            namespace={ namespace }
            descriptionKey={ descriptionKey }
            permission={ permission }
            context={ context }
            teamArea={ teamArea }
            setForm={ setForm }
            setDisabled={ setDisabled }
            user={ user }
            callback={ callback }
          />
        )
      },
      footer: {
        actionsElement: getActionsElement()
      }
    });
  };

  useEffect(
    () => {
      if (form) {
        modalService
          .patchModalOptions({
            id: 'teamEdit',
            footer: {
              actionsElement: getActionsElement()
            }
          });
      }
    },
    [ form, disabled ]
  );

  useEffect(() => {
    modalService.patchContentProperties('teamEdit', { emailErrorMessage });
  }, [ emailErrorMessage ]);

  return { showModal };
};
