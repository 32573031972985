import { getAcceptedRouteTab, isUuid, ResolverData } from '@router';
import { Observable, of, throwError } from 'rxjs';
import { AssetManagementTabsKey } from '@services';
import { AssetFormKey } from '@proliance-ai/typings';

export interface RouteParameters {
  routeTab?: null | AssetManagementTabsKey;
  id?: string;
  sidebar?: AssetFormKey;
}

const AssetManagementSidebarDictionary: Record<AssetManagementTabsKey, AssetFormKey[]> = {
  DATA_STORAGES: [ 'management', 'type', 'operators', 'dataTypes', 'description', 'accesses', 'rpaAsController', 'rpaAsProcessor' ],
  SOFTWARE: [ 'management', 'type', 'operators', 'vendor', 'dataTypes', 'description', 'categories', 'accesses', 'rpaAsController', 'rpaAsProcessor' ],
  THIRD_PARTIES: [ 'management', 'type', 'legalStatuses', 'dataTypes', 'transmissionCountries', 'documents', 'description', 'contractualDeadlines', 'contact', 'privacyContact', 'services', 'accesses', 'rpaAsController', 'rpaAsProcessor' ],
  PRINCIPAL: [],
  WEBSITE: []
};

function isAssetFormKey(key: string, type: AssetManagementTabsKey): key is AssetFormKey {
  return AssetManagementSidebarDictionary[type].includes(key as AssetFormKey);
}

export const assetManagementResolver = (data: ResolverData): Observable<RouteParameters> => {
  const {
    routeTab: routeTabParameter,
    id: idParameter,
    sidebar: sidebarParameter
  } = data.parameter;

  const isDefaultTab = !routeTabParameter || isUuid(routeTabParameter);

  const routeTab = isDefaultTab
    ? AssetManagementTabsKey.SOFTWARE
    : getAcceptedRouteTab<AssetManagementTabsKey>('assetManagement', data);
  if (!routeTab) {
    return throwError({ status: 404 });
  }

  const id = isDefaultTab
    ? routeTabParameter
    : idParameter;
  if (!id) {
    return of({ routeTab });
  }
  if (!isUuid(id) && id !== 'add') {
    return throwError({ status: 404 });
  }

  const sidebar = isDefaultTab
    ? idParameter
    : sidebarParameter;
  if (!sidebar) {
    return of({ routeTab, id });
  }
  if (!isAssetFormKey(sidebar, routeTab)) {
    return throwError({ status: 404 });
  }
  return of({ routeTab, id, sidebar });
};
