import {
  AssetKey,
  AssetTypeKey,
  DataStorageFormKey,
  Priority,
  SoftwareFormKey,
  ThirdPartyFormKey
} from '@proliance-ai/typings';
import { GetPriority } from '../assetManagement.typings';
import { dataStoragePriorityMapping, softwarePriorityMapping, thirdPartyPriorityMapping } from '../data';

const getPriorityValue = (value?: Priority): Priority => typeof value !== 'undefined'
  ? value
  : Priority.OPTIONAL;

export const getPriority: GetPriority = (type: AssetTypeKey, key: AssetKey): Priority => {
  switch (type) {
    case 'ThirdParty':
      return getPriorityValue(thirdPartyPriorityMapping[key as ThirdPartyFormKey]);
    case 'DataStorage':
      return getPriorityValue(dataStoragePriorityMapping[key as DataStorageFormKey]);
    case 'Software':
      return getPriorityValue(softwarePriorityMapping[key as SoftwareFormKey]);
    default:
      return Priority.OPTIONAL;
  }
};

