import { BehaviorSubject, Observable } from 'rxjs';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import {
  Asset,
  AssetFormKey,
  AssetManagementFetchData,
  AssetManagementFilterData,
  AssetTemplateDataStorageData,
  AssetTemplateSoftwareData,
  AssetTemplateThirdPartyData,
  AssetTypeKey,
  ChipPreset,
  Collection,
  Comment,
  CommentData,
  CommentRequestParameters,
  CustomCategory,
  DataStorage,
  DataStorageDataType,
  DataStorageFormKey,
  DataStorageKey,
  DataStorageTemplate,
  DictionaryResponse,
  InstrumentData,
  Locale,
  Priority,
  SidebarKnowledgeBaseData,
  Software,
  SoftwareDataType,
  SoftwareFormKey,
  SoftwareKey,
  SoftwareTemplate,
  ThirdParty,
  ThirdPartyDataType,
  ThirdPartyFormKey,
  ThirdPartyKey,
  TranslationInfo,
  ThirdPartyTemplate,
  User,
  WorkFlowData, UpdateTransition
} from '@proliance-ai/typings';

export enum AssetManagementTabsKey {
  SOFTWARE = 'SOFTWARE',
  THIRD_PARTIES = 'THIRD_PARTIES',
  DATA_STORAGES = 'DATA_STORAGES',
  PRINCIPAL = 'PRINCIPAL',
  WEBSITE = 'WEBSITE'
}

export enum ManagementSidebarTabsKey {
  ADMINISTRATION = 'ADMINISTRATION',
  COMMENTS = 'COMMENTS',
  DPO_REVIEW = 'DPO_REVIEW',
  PROTOCOL = 'PROTOCOL'
}

// export enum FileType {
//   PDF = 'application/pdf',
//   DOC = 'application/msword',
//   DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
//   XLS = 'application/vnd.ms-excel',
//   XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
//   CSV = 'text/csv',
//   OTHER = 'application/octet-stream'
// }

export type GetPriority = {
  (type: 'DataStorage', key: DataStorageKey): Priority;
  (type: 'Software', key: SoftwareKey): Priority;
  (type: 'ThirdParty', key: ThirdPartyKey): Priority;
};
export type GetNextStep = {
  (type: 'DataStorage', currentStep: DataStorageFormKey): null | DataStorageFormKey;
  (type: 'Software', currentStep: SoftwareFormKey): null | SoftwareFormKey;
  (type: 'ThirdParty', currentStep: ThirdPartyFormKey): null | ThirdPartyFormKey;
};

export type AssetManagementSidebarData<T extends Asset> = {
  name: AssetFormKey;
  asset: T;
};

export interface FetchDataOptionsId {
  id: string;
  isDeleted?: boolean;
}

export interface FetchDataOptionsAsset<T extends Asset> {
  asset: T;
}

export interface FetchDataOptionsParameters<T extends Asset> {
  parameters?: AssetManagementFetchData<T>;
}

export type FetchDataOptions<T extends Asset> = FetchDataOptionsId | FetchDataOptionsAsset<T> | FetchDataOptionsParameters<T>;
export type FetchAssetManagementData<T extends Asset> = {
  (options: FetchDataOptionsId): Promise<null | T>;
  (options: FetchDataOptionsAsset<T>): Promise<T>;
  (options?: FetchDataOptionsParameters<T>): Promise<null | Collection<T>>;
};

export interface GetCountryTitleOptions {
  country: string;
  locale: Locale;
  dictionaryData: Array<DictionaryResponse<TranslationInfo>>;
}

export type SidebarKnowledgeBaseDataCache<T> = Partial<Record<AssetFormKey, T>>;
export type KnowledgeBaseDataCache<T> = Partial<Record<AssetTypeKey, SidebarKnowledgeBaseDataCache<T>>>;

export interface AssetManagementService {
  sidebar: BehaviorSubject<undefined | AssetManagementSidebarData<Asset>>;
  sidebar$: Observable<undefined | AssetManagementSidebarData<Asset>>;
  openSidebar: <T extends Asset>(data: AssetManagementSidebarData<T>) => void;
  updateSidebar: <T extends Asset>(id: string, data: AssetManagementSidebarData<T>) => void;
  closeSidebar: (handleRoute?: boolean) => void;

  filterAssignee$: BehaviorSubject<null | User>;
  filter$: BehaviorSubject<AssetManagementFilterData>;
  getFilterCount: () => number;

  showNext: BehaviorSubject<undefined | boolean>;

  getCountryTitle: (country: string) => Promise<string>;

  getIcon: (type: AssetTypeKey) => IconDefinition;
  getNextStep: GetNextStep;
  getPriority: GetPriority;
  getPriorityPreset: (priority: Priority) => ChipPreset;

  deleteFile: (id: string, documentId: string) => Observable<null>;

  getThirdPartyCollection: (parameters?: Record<string, any>) => Observable<Collection<ThirdParty>>;
  getThirdParty: (id: string) => Observable<ThirdParty>;
  getThirdPartyTemplateCollection: (parameters?: Record<string, any>) => Observable<Collection<ThirdPartyTemplate>>;
  getThirdPartySuggestedSoftwareTemplateCollection: (parameters?: Record<string, any>) => Observable<Collection<SoftwareTemplate>>;
  getThirdPartySuggestedDataStorageTemplateCollection: (parameters?: Record<string, any>) => Observable<Collection<DataStorageTemplate>>;
  createThirdParty: (body: ThirdPartyDataType) => Observable<ThirdParty>;
  updateThirdParty: (id: string, body: Partial<ThirdParty>) => Observable<ThirdParty>;
  deleteThirdParty: (id: string) => Observable<void>;
  getInstrumentDataList: (country: string) => Observable<InstrumentData[]>;
  getThirdPartyTemplate: (id: string) => Observable<AssetTemplateThirdPartyData>;

  getSoftwareCollection: (parameters?: Record<string, any>) => Observable<Collection<Software>>;
  getSoftware: (id: string) => Observable<Software>;
  getSoftwareTemplateCollection: (parameters?: Record<string, any>) => Observable<Collection<SoftwareTemplate>>;
  getSoftwareSuggestedThirdPartyTemplateCollection: (parameters?: Record<string, any>) => Observable<Collection<ThirdPartyTemplate>>;
  createSoftware: (body: SoftwareDataType) => Observable<Software>;
  updateSoftware: (id: string, body: Partial<Software>) => Observable<Software>;
  deleteSoftware: (id: string) => Observable<void>;
  getSoftwareCategories : () => Observable<CustomCategory[]>;
  getSoftwareTemplate: (id: string) => Observable<AssetTemplateSoftwareData>;

  getDataStorageCollection: (parameters?: Record<string, any>) => Observable<Collection<DataStorage>>;
  getDataStorage: (id: string) => Observable<DataStorage>;
  getDataStorageTemplateCollection: (parameters?: Record<string, any>) => Observable<Collection<DataStorageTemplate>>;
  getDataStorageSuggestedThirdPartyTemplateCollection: (parameters?: Record<string, any>) => Observable<Collection<ThirdPartyTemplate>>;
  createDataStorage: (body: DataStorageDataType) => Observable<DataStorage>;
  updateDataStorage: (id: string, body: Partial<DataStorage>) => Observable<DataStorage>;
  deleteDataStorage: (id: string) => Observable<void>;
  getDataStorageTemplate: (id: string) => Observable<AssetTemplateDataStorageData>;

  getCommentCollection: (parameters: CommentRequestParameters) => Observable<Collection<Comment>>;
  createComment: (commentData: CommentData) => Observable<Comment>;
  updateComment: (comment: Comment) => Observable<null | Comment>;
  deleteComment: (commentId: string) => Observable<null>;

  getTransitionById: (id: string) => Observable<WorkFlowData>;
  updateTransition: (data: UpdateTransition) => Observable<WorkFlowData>;

  getSidebarKnowledgeBaseData: (assetType: AssetTypeKey, sidebarName: AssetFormKey) => Observable<SidebarKnowledgeBaseData>;
}
