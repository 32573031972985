// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".text-SystemDeleteErrorModalContent_GNF54{margin:0 0 24px;color:#475569;font-size:14px;font-weight:500;line-height:19.6px}.heading-SystemDeleteErrorModalContent_se51d{margin:0 0 12px;color:#828282;font-size:14px;font-weight:700;line-height:19.6px;text-transform:uppercase}.box-SystemDeleteErrorModalContent_eeA8P{width:100%;padding:12px;box-sizing:border-box;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:10px;background-color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "text-SystemDeleteErrorModalContent_GNF54",
	"heading": "heading-SystemDeleteErrorModalContent_se51d",
	"box": "box-SystemDeleteErrorModalContent_eeA8P"
};
export default ___CSS_LOADER_EXPORT___;
